<template>
  <div>
    <div class="o__wrapper">
      <div class="o__price">
        <div class="product-price">
          <div class="product-price__main">
            <span>{{ totalPrice() }}</span> {{ productCurrency }}
          </div>
        </div>
      </div>

    </div>
    <div class="e__wrapper">
      <div @click="amountEyes = 1" class="e__eyes">
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M25.8348 12.4944C25.6025 12.1766 20.0682 4.71436 12.9999 4.71436C5.93157 4.71436 0.397009 12.1766 0.164989 12.4941C-0.0549962 12.7955 -0.0549962 13.2043 0.164989 13.5058C0.397009 13.8235 5.93157 21.2858 12.9999 21.2858C20.0682 21.2858 25.6025 13.8235 25.8348 13.506C26.055 13.2046 26.055 12.7955 25.8348 12.4944ZM12.9999 19.5715C7.79331 19.5715 3.28388 14.6186 1.94899 12.9995C3.28215 11.3789 7.78214 6.42863 12.9999 6.42863C18.2062 6.42863 22.7153 11.3806 24.0508 13.0007C22.7176 14.6212 18.2176 19.5715 12.9999 19.5715Z"
              fill="#007eef"></path>
          <path
              d="M13.1429 7.85742C10.3071 7.85742 8 10.1646 8 13.0003C8 15.836 10.3071 18.1432 13.1429 18.1432C15.9786 18.1432 18.2858 15.836 18.2858 13.0003C18.2858 10.1646 15.9786 7.85742 13.1429 7.85742ZM13.1429 14C11.2523 14 14.6445 14.8905 14.6445 13C14.6445 11.1095 11.754 12 13.6445 12C15.5351 12 12.1445 11.1095 12.1445 13C12.1445 14.8905 15.0335 14 13.1429 14Z"
              fill="#007eef" v-if="amountEyes === 1"></path>
        </svg>
        <div>Однакові</div>
      </div>
      <div @click="amountEyes = 2" class="e__eyes">
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M25.8348 12.4944C25.6025 12.1766 20.0682 4.71436 12.9999 4.71436C5.93157 4.71436 0.397009 12.1766 0.164989 12.4941C-0.0549962 12.7955 -0.0549962 13.2043 0.164989 13.5058C0.397009 13.8235 5.93157 21.2858 12.9999 21.2858C20.0682 21.2858 25.6025 13.8235 25.8348 13.506C26.055 13.2046 26.055 12.7955 25.8348 12.4944ZM12.9999 19.5715C7.79331 19.5715 3.28388 14.6186 1.94899 12.9995C3.28215 11.3789 7.78214 6.42863 12.9999 6.42863C18.2062 6.42863 22.7153 11.3806 24.0508 13.0007C22.7176 14.6212 18.2176 19.5715 12.9999 19.5715Z"
              fill="#007eef"></path>
          <path
              d="M13.1429 7.85742C10.3071 7.85742 8 10.1646 8 13.0003C8 15.836 10.3071 18.1432 13.1429 18.1432C15.9786 18.1432 18.2858 15.836 18.2858 13.0003C18.2858 10.1646 15.9786 7.85742 13.1429 7.85742ZM13.1429 14C11.2523 14 14.6445 14.8905 14.6445 13C14.6445 11.1095 11.754 12 13.6445 12C15.5351 12 12.1445 11.1095 12.1445 13C12.1445 14.8905 15.0335 14 13.1429 14Z"
              fill="#007eef" v-if="amountEyes === 2"></path>
        </svg>
        <div>Різні</div>
      </div>
    </div>
    <div class="v__wrapper" :style="(variants.length <= 1) ? 'display:none;' : ''">
      <div class="el__left">
        {{ variantName }}
      </div>
      <div class="el__right">
        <select class="variants-select__field  variants-select__field--lg" style="max-width: 150px"
                v-model="selectedVariant1" @change="changeVariant(0)">
          <option
              v-for="(variant, key) in variants"
              :value="variant.id"
              @change="setCurrentVariant(variant, 1)"
          >
            {{ variant.title }}
          </option>
        </select>
      </div>
      <div class="el__right" v-if="amountEyes === 2">
        <select class="variants-select__field  variants-select__field--lg" style="max-width: 150px"
                v-model="selectedVariant2" @change="changeVariant(1)">
          <option
              v-for="(variant, key) in variants"
              :value="variant.id"
              @change="setCurrentVariant(variant, 1)"
              :disabled="variant.stock === 0"
          >
            {{ variant.title }}
          </option>
        </select>
      </div>
    </div>

    <div v-if="productTypeOfLens">
      <select class="variants-select__field variants-select__field--lg" @change="changeCategory" v-model="productId">
        <option :value="null">Not selected</option>
        <option
            v-for="category in innerTypeOfLensCategory"
            v-html="category.text"
            :value="category.id"
            :selected="productId === category.id"/>
      </select>
    </div>

    <!--    -->
    <!--    -->
    <!--    -->
    <div class="v__wrapper" v-if="this.firstItems1.length > 0">
      <div class="el__left">
        СФЕРА
        <span>*</span>
      </div>
      <div class="el__left">
        <select
            :class="(priceFirst1 === null && isSubmitted === true) ? 'variants-select__field variants-select__field--lg required-field' : 'variants-select__field  variants-select__field--lg'"
            style="max-width: 150px"
            v-model="priceFirst1"
            @change="fetchSecondPrice(1)">
          <option
              v-for="(property, key) in this.firstItems1"
              :value="property.first"
              :key="key"
          >
            {{ property.first }}
          </option>
        </select>
      </div>
      <div class="el__right" v-if="amountEyes === 2">
        <select
            :class="(priceFirst2 === null && isSubmitted === true) ? 'variants-select__field variants-select__field--lg required-field' : 'variants-select__field  variants-select__field--lg'"
            style="max-width: 150px"
            v-model="priceFirst2"
            @change="fetchSecondPrice(2)">
          <option
              v-for="(property, key) in this.firstItems2"
              :value="property.first"
              :key="key"
          >
            {{ property.first }}
          </option>
        </select>
      </div>
    </div>

    <div class="v__wrapper" v-if="secondItemsSum < 1000">
      <div class="el__left">
        ЦИЛІНДР
        <span>*</span>
      </div>
      <div class="el__left">
        <select
            :class="(priceSecond1 === null && isSubmitted === true) ? 'variants-select__field variants-select__field--lg required-field' : 'variants-select__field  variants-select__field--lg'"
            style="max-width: 150px"
            v-model="priceSecond1"
            @change="selectedSecondProperty(1)">
          <option
              v-for="(property, key) in this.secondItems1"
              :value="property.second"
              :key="key"
          >
            {{ property.second }}
          </option>
        </select>
      </div>
      <div class="el__right" v-if="amountEyes === 2">
        <select
            :class="(priceSecond2 === null && isSubmitted === true) ? 'variants-select__field variants-select__field--lg required-field' : 'variants-select__field  variants-select__field--lg'"
            style="max-width: 150px"
            v-model="priceSecond2"
            @change="selectedSecondProperty(2)">
          <option
              v-for="(property, key) in this.secondItems2"
              :value="property.second"
              :key="key"
          >
            {{ property.second }}
          </option>
        </select>
      </div>
    </div>

    <!--    -->
    <!--    -->
    <!--    -->

    <div v-if="attributes.length > 0"
         v-for="(attribute, keyAttribute) in attributes"
         :key="keyAttribute">
      <div class="v__wrapper" v-if="attribute.name.length > 0">
        <div class="el__left">
          {{ attribute.name }}
          <span v-if="attribute.required ==1">*</span>
        </div>
        <div class="el__right">
          <label>
            <select :name="String(keyAttribute) + '-1'" v-model="attributes1[String(keyAttribute)]"
                    :class="getSelectColor(attribute, attributes1[String(keyAttribute)])"
                    style="max-width:100px;text-align:center; ">
              <option disabled value=""> --</option>
              <option v-for="(attribute, key) in attribute.text" :value="attribute">{{ attribute }}</option>
            </select>
          </label>
        </div>
        <div class="el__right" v-if="amountEyes === 2">
          <label>
            <select :name="String(keyAttribute) + '-2'" v-model="attributes2[String(keyAttribute)]"
                    :class="getSelectColor(attribute, attributes2[String(keyAttribute)])"
                    style="max-width:100px;text-align:center;">
              <option disabled value=""> --</option>
              <option v-for="(attribute, key) in attribute.text" :value="attribute">{{ attribute }}</option>
            </select>
          </label>
        </div>
      </div>
    </div>

    <div class="v__wrapper">
      <div class="el__left">
        Оберіть кількість
      </div>
      <div class="el__right">
        <input type="number" v-model="amount1" class="form-input__control form-input__control--quantity"
               style="max-width:100px;text-align:center;">
      </div>
      <div class="el__right" v-if="amountEyes === 2 && vueCategoryDouble===0">
        <input type="number" v-model="amount2" class="form-input__control form-input__control--quantity"
               style="max-width:100px;text-align:center;">
      </div>
    </div>


    <div class="v__wrapper" v-if="innerTypeOfLensCategory">
      <div class="el__left">
        Ціна
      </div>
      <div class="el__right">
        {{ this.productPrice1 * this.amount1 }} ГРН
      </div>
      <div class="el__right" v-if="amountEyes === 2 && vueCategoryDouble===0">
        {{ this.productPrice2 * this.amount2 }} ГРН
      </div>
    </div>


    <div class="alert" v-if="isAvailableSubmit()===false && isSubmitted">
      Поля з * обов'язкові до заповнення
    </div>
    <!--If added to Cart  -->

    <div v-if="addedToCart" class="added_to_cart"> Товар уже в корзині</div>
    <div v-if="alertMessage" class="added_to_cart"> {{ alertMessage }}</div>
    <div class="product-intro__flex-container" style="border-top: 1px solid #c5c5c5;margin-top: 20px;">
      <!-- Product add to cart button -->

      <!-- Items in stock -->
      <div class="product-purchase " data-product-available="">
        <button class="btn btn-primary btn-lg" type="button" @click="addToCart()" :disabled="cartDisabled">
          <span v-if="cartDisabled">Нема в наявності</span>
          <span v-else>Додати в корзину</span>
        </button>
      </div>

      <!-- Wishlist buttons. Dont show button on whishlist page -->
      <div class="product-action product-action--lg" data-ajax-inject="wishlist-btn-18845">

        <!-- Button link, used in catalog page -->
        <a class="btn-white  product-photo__action-btn" href="/ua/wishlist/renderPopup/18845" data-modal=""
           rel="nofollow">
          <i class="btn-white__ico btn-white__ico--wishlist">
            <svg class="svg-icon">
              <use xlink:href="/templates/megashop/_img/sprite.svg#svg-icon__heart-fill"></use>
            </svg>
            <span class="icon-heart"></span> </i>
        </a>
      </div>
      <!-- Edit and remove buttons. Display only on wishlist page -->
      <!-- "Add to" or "Open in" compare buttons -->
      <div class="product-action product-action--lg">

        <div data-compare-scope="add_to">

          <!-- Item isn't in compare list -->
          <button class="btn-white product-photo__action-btn " type="button" rel="nofollow"
                  data-compare-add="/shop/compare_api/add/17956">
            <i class="btn-white__ico btn-white__ico--compare">

              <span class="icon-libra"></span> </i>
          </button>

          <!-- Item already is in compare list -->
          <a class="btn-white btn-white--active product-photo__action-btn  hidden" href="/shop/compare" rel="nofollow"
             data-compare-open="">
            <i class="btn-white__ico btn-white__ico--compare">
              <span class="icon-libra"></span> </i>
          </a>

        </div>
      </div>
    </div>
    <div class="o_buy">

    </div>

    <!--
        <a class="btn btn-default btn-lg"
           href="http://prooko.loc/shop/ajax/getNotifyingRequest"
           data-product-notify="{{productId}}"
           data-product-notify-variant="{{selectedVariant1}}"
           rel="nofollow">
          Повідомити про появу
        </a> -->
  </div>
</template>

<script>
import ProductVueFullScpeen from "./ProductVueFullScpeen.vue";

const axios = require('axios')


export default {
  name: "ProductVue",
  components: {ProductVueFullScpeen},
  data: () => ({
    secondItemsSum: 0,
    lockAmountUpdate: false,
    alertMessage: null,
    cartDisabled: false,
    productId: 0,
    isLoading: false,
    variants: [],
    attributes: [],
    amountEyes: 1,
    productPrice1: 0,
    productPrice2: 0,
    amount1: 1,
    amount2: 1,
    selectedVariant1: 0,
    selectedVariant2: 0,
    attributes1: {},
    attributes2: {},
    productCurrency: 'грн',
    isSubmitted: false,
    variantName: '',
    addedToCart: false,
    vueCategoryDouble: false,

    priceFirst1: null,
    priceSecond1: 0,
    firstItems1: [],
    secondItems1: [{
      second: 0,
    }],
    priceFirst2: null,
    priceSecond2: 0,
    firstItems2: [],
    secondItems2: [{
      second: 0,
    }],
    productTypeOfLens: false,
    innerTypeOfLensCategory: [],
  }),
  methods: {
    changeCategory() {
      this.innerTypeOfLensCategory.forEach(category => {
        if (category.id === this.productId) {
          window.location.href = category.url;
        }
      })
      console.log();
    },
    getSelectColor(attribute, selectedAttribute) {
      if (attribute.required === "1" && typeof selectedAttribute === "undefined" && this.isSubmitted === true) {
        return ['select-style', 'variants-select__field', 'variants-select__field--lg', 'required-field'];
      }
      return ['select-style', 'variants-select__field', 'variants-select__field--lg'];
    },
    async addToCart() {
      let access = this.isAvailableSubmit();
      this.isSubmitted = true;
      this.isLoading = true;
      try {
        if (access === true) {
          let props = {
            productId: this.productId,
            selectedVariant1: this.selectedVariant1,
            selectedVariant2: this.selectedVariant2,
            attributes1: this.attributes1,
            attributes2: this.attributes2,
            amountEyes: this.amountEyes,
            amount1: this.amount1,
            amount2: this.amount2,
            priceFirst1: this.priceFirst1,
            priceSecond1: this.priceSecond1,
          };
          if (this.amountEyes === 2) {
            props.priceFirst2 = this.priceFirst2;
            props.priceSecond2 = this.priceSecond2;
          }
          const response = await axios.post(window.location.origin + '/product_vue/add_to_cart', props);
          if (response.data.length !== 0) {
            alert(response.data);
            access = false;
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        if (access === true) {
          window.location.href = window.location.href + '?showCart=true';
        }
      }
      this.isLoading = false;

    },

    /*

     */
    isAvailableSubmit() {
      let access = true;
      for (var i = 0; i < this.attributes.length; i++) {
        if (this.attributes[i].required === '1') {
          if (typeof this.attributes1[i] === "undefined") {
            access = false;
          }
          if (this.amountEyes === 2 && typeof this.attributes2[i] === "undefined") {
            access = false;
          }
        }
      }
      // check first important param
      if (this.firstItems1.length > 0 && (this.priceFirst1 === null || isNaN(this.priceFirst1))) {
        access = false;
      }
      // check second important param
      if (this.secondItems1.length > 0 && (this.priceSecond1 === null || isNaN(this.priceSecond1))) {
        access = false;
      }
      // check for 2 eye
      if (this.amountEyes === 2) {
        // check first important param
        if (this.firstItems2.length > 0 && (this.priceFirst2 === null || isNaN(this.priceFirst2))) {
          access = false;
        }
        // check second important param
        if (this.secondItems2.length > 0 && (this.priceSecond2 === null || isNaN(this.priceSecond2))) {
          access = false;
        }
      }
      return access;
    },


    setCurrentVariant(variant, key) {
      if (key === 1) {
        this.productPrice1 = variant.price;
        this.selectedVariant1 = variant.id;
      } else {
        this.productPrice2 = variant.price;
        this.selectedVariant2 = variant.id;
      }
    },
    changeVariant(key) {
      if (key === 0) {
        for (var i = 0; i < this.variants.length; i++) {
          if (this.variants[i]['id'] === this.selectedVariant1) {
            this.productPrice1 = this.variants[i].price;
            let mainProductImage = document.querySelector('[data-product-photo]');
            mainProductImage.src = this.variants[i].image;
            mainProductImage.dataset.dataZoomImage = this.variants[i].image;
          }
        }
      } else {
        for (var i = 0; i < this.variants.length; i++) {
          if (this.variants[i]['id'] === this.selectedVariant2) {
            this.productPrice2 = this.variants[i].price;
          }
        }
      }
      console.log();
    },
    setCurrentAttribute(attribute, keyAttribute, key) {
      if (key === 1) {
        this.attributes1[String(keyAttribute)] = attribute;
      } else {
        this.attributes2[String(keyAttribute)] = attribute;
      }
    },
    totalPrice() {
      if (this.amountEyes === 1) {
        if (
            this.firstItems1.length > 0
            && (this.priceFirst1 === null || isNaN(this.priceFirst1))
            && (this.priceSecond1 === null || isNaN(this.priceSecond1))
        ) {
          return '---';
        }
      }
      if (this.vueCategoryDouble === 0) {
        return this.amountEyes === 1 ? this.productPrice1 * this.amount1 : (this.productPrice1 * this.amount1) + (this.productPrice2 * this.amount2);
      } else {
        return this.productPrice1 * this.amount1;
      }
    },
    async loadVariantData(productId) {
      try {
        this.isLoading = true;
        const response = await axios.get(window.location.origin + '/product_vue/variants?productId=' + productId);
        this.variants = response.data.variants;
        this.firstItems1 = response.data.firstItems;
        this.firstItems2 = response.data.firstItems;
        this.secondItemsSum = response.data.secondItemsSum;
        this.attributes = response.data.attributes;
        this.variantName = response.data.variantName;
        this.addedToCart = response.data.addedToCart;
        this.vueCategoryDouble = response.data.vueCategoryDouble;
        this.setCurrentVariant(this.variants[0], 1);
        this.setCurrentVariant(this.variants[0], 2);
        this.productTypeOfLens = response.data.productTypeOfLens;
        this.innerTypeOfLensCategory = response.data.innerTypeOfLensCategory;

      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    checkStock(changedVariant = false) {
      if (this.lockAmountUpdate === true) {
        return;
      }

      if (changedVariant === true) {
        this.lockAmountUpdate = true;
        this.amount1 = 1;
        this.amount2 = 1;
        this.lockAmountUpdate = false;
      }
      this.alertMessage = null;
      this.lockAmountUpdate = true;
      // get data
      let stock1 = 0;
      let title1 = '';
      for (var i = 0; i < this.variants.length; i++) {
        if (this.variants[i]['id'] === this.selectedVariant1) {
          stock1 = this.variants[i]['stock'];
          title1 = this.variants[i]['title'];
        }
      }
      let stock2 = 0;
      let title2 = '';
      for (var i = 0; i < this.variants.length; i++) {
        if (this.variants[i]['id'] === this.selectedVariant2) {
          stock2 = this.variants[i]['stock'];
          title2 = this.variants[i]['title'];
        }
      }
      // calculate

      if (this.amount1 < 0) {
        this.amount1 = 1;
      }
      if (this.amount2 < 0) {
        this.amount2 = 0;
      }
      if (this.amount1 > stock1 && stock1 !== 0) {
        // this.alertMessage = 'На жаль, у нас в наявності "' + title1 + '" лише ' + stock1 + ' шт.';
        if (title1 !== null) {
          alert('На жаль, у нас в наявності "' + title1 + '" лише ' + stock1 + ' одиниць.');
        }
        this.amount1 = stock1;
        this.cartDisabled = false;
        this.lockAmountUpdate = false;
        return;
      } else if (stock1 === 0) {
        this.cartDisabled = true;
        this.amount1 = 0;
      } else {
        this.cartDisabled = false;
      }

      if (this.vueCategoryDouble === 0 && this.amountEyes === 2) {
        if (this.selectedVariant1 === this.selectedVariant2) {
          // якщо на два ока однакові
          let sumAmount = +this.amount1 + +this.amount2;
          console.log(sumAmount);
          console.log(stock1);
          if (sumAmount > stock1 && stock1 >= 2) {
            // this.alertMessage = 'На жаль, у нас в наявності "' + title1 + '" лише ' + stock1 + ' шт.';
            if (title1 !== null) {
              alert('На жаль, у нас в наявності "' + title1 + '" лише ' + stock1 + ' одиниць.');
            }
            this.amount1 = 1;
            this.amount2 = 1;
            this.cartDisabled = false;
            this.lockAmountUpdate = false;
            return;
          } else if (stock1 < 2 && sumAmount !== 0) {
            // this.alertMessage = 'На жаль, у нас в наявності "' + title1 + '" лише ' + stock1 + ' шт.';
            if (title1 !== null) {
              alert('На жаль, у нас в наявності "' + title1 + '" лише ' + stock1 + ' одиниць.');
            }
            this.cartDisabled = true;
            this.amount1 = 0;
            this.amount2 = 0;
            this.lockAmountUpdate = false;
            return;
          } else {
            this.cartDisabled = false;
          }
        } else {
          // якщо на два ока різні
          if (this.amount2 > stock2 && stock2 !== 0) {
            // this.alertMessage = 'На жаль, у нас в наявності "' + title1 + '" лише ' + stock1 + ' шт.';
            if (title1 !== null) {
              alert('На жаль, у нас в наявності "' + title2 + '" лише ' + stock2 + ' одиниць.');
            }
            this.amount2 = stock2;
            this.cartDisabled = false;
            this.lockAmountUpdate = false;
            return;
          } else if (stock2 === 0) {
            this.cartDisabled = true;
            this.amount2 = 0;
          } else {
            this.cartDisabled = false;
          }
        }
      } else if (this.amountEyes === 2) {
        if (this.selectedVariant1 === this.selectedVariant2) {
          // якщо на два ока однакові
          let sumAmount = this.amount1;
          // console.log(sumAmount);
          if (sumAmount > stock1 && stock1 >= 1) {
            // this.alertMessage = 'На жаль, у нас в наявності "' + title1 + '" лише ' + stock1 + ' шт.';
            if (title1 !== null) {
              alert('На жаль, у нас в наявності "' + title1 + '" лише ' + stock1 + ' одиниць.');
            }
            this.amount1 = 1;
            this.cartDisabled = false;
            this.lockAmountUpdate = false;
            return;
          } else if (stock1 < 1 && this.amount1 !== 0) {
            // this.alertMessage = 'На жаль, у нас в наявності "' + title1 + '" лише ' + stock1 + ' шт.';
            if (title1 !== null) {
              alert('На жаль, у нас в наявності "' + title1 + '" лише ' + stock1 + ' одиниць.');
            }
            this.cartDisabled = true;
            this.amount1 = 0;
            this.lockAmountUpdate = false;
            return;
          } else {
            this.cartDisabled = false;
          }
        } else {
          // якщо на два ока різні
          if (this.amount1 > stock1 && stock1 > 0) {
            // this.alertMessage = 'На жаль, у нас в наявності "' + title1 + '" лише ' + stock1 + ' шт.';
            if (title1 !== null) {
              alert('На жаль, у нас в наявності "' + title1 + '" лише ' + stock1 + ' одиниць.');
            }
            this.amount1 = 1;
            this.cartDisabled = false;
            this.lockAmountUpdate = false;
            return;
          } else if (stock1 === 0) {
            this.cartDisabled = true;
            this.amount1 = 0;
          } else {
            this.cartDisabled = false;
          }
        }


      }
      if (this.amount1 === 0) {
        this.cartDisabled = true;
      }
      this.lockAmountUpdate = false;
    },

    /*
     * Fetch second price
     */
    async fetchSecondPrice(oko = 1) {
      let firstSelected = null;
      if (oko === 1) {
        firstSelected = this.priceFirst1;
      } else {
        firstSelected = this.priceFirst2;
      }
      let url = window.location.origin + '/product_vue/secondPrice';
      await axios.get(url, {params: {productId: this.productId, first: firstSelected}}).then(response => {
        if (oko === 1) {
          this.priceSecond1 = null;
          this.secondItems1 = response.data.items;
          this.priceSecond1 = response.data.selectedSecond;
        } else {
          this.priceSecond2 = null;
          this.secondItems2 = response.data.items;
          this.priceSecond2 = response.data.selectedSecond;
        }
        this.selectedSecondProperty(oko);
      });
    },
    /*

     */
    selectedSecondProperty(oko = 1) {
      if (oko === 1) {
        this.productPrice1 = 0;
        this.secondItems1.forEach(item => {
          if (item.second === this.priceSecond1) {
            this.productPrice1 = item.price;
          }
        });
      } else {
        this.productPrice2 = 0;
        this.secondItems2.forEach(item => {
          if (item.second === this.priceSecond2) {
            this.productPrice2 = item.price;
          }
        });
      }
    }
  },
  watch: {
    amount1() {
      this.checkStock();
    },
    amount2() {
      this.checkStock();
    },
    selectedVariant1() {
      this.checkStock(true);
    },
    selectedVariant2() {
      this.checkStock(true);
    },
    amountEyes() {
      this.checkStock();
    }
  },
  async mounted() {
    this.productId = jQuery('[data-variant-id]').data('variant-id');
    await this.loadVariantData(this.productId);
    jQuery('.product-intro-base').hide();
    jQuery('.product-intro__flex-container--v-bottom').hide();
    if (this.productTypeOfLens) {
      this.amountEyes = 2;
      this.checkStock();
      if (this.productTypeOfLens) {
        document.querySelector('.page__breadcrumbs').style.display = 'none';
        document.querySelector('.content__sidebar').style.display = 'none';
        $('.product-intro__row').hide();
      }
    }
  }

}
</script>

<style scoped>


.o__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #c5c5c5;
}

.v__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.el__left {
  width: 50%;
  padding: 5px;
  text-align: right;
}

.el__right {
  width: 50%;
  padding: 10px;
}

.e__wrapper {
  display: flex;
  flex-direction: row;
}

.e__eyes {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.e__eyes:hover {
  cursor: pointer;
}

.variants {
  padding: 2px;
}

.variants * {
  padding: 3px;
}

.required-field {
  background-color: red;
}

.alert {
  padding: 20px;
  background-color: #f44336; /* Red */
  color: white;
  margin-bottom: 15px;
}

.added_to_cart {
  border: 2px solid #007eef;
  padding: 10px;
  margin: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.select-style {
  font-size: 18px;
}
</style>