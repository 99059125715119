<script>
import axios from "axios";
import {Swiper, SwiperSlide} from "swiper/vue";
import ProductParamsSelector from "./ProductParamsSelector.vue";
import LoadingAnimation from "../LoadingAnimation.vue";
import ProductPrice from "./ProductPrice.vue";

export default {
  name: "ProductVueFullScpeen",
  components: {
    Swiper,
    SwiperSlide,
    ProductParamsSelector,
    LoadingAnimation,
    ProductPrice,
  },
  mounted() {
    this.productId = parseInt(document.querySelector('[data-product-id]').dataset.productId);
    this.initData();
  },
  data() {
    return {
      fullScreenModal: false,
      currentStage: 0,
      isLoading: true,
      domain: '/',
      image: null,
      brands: [],
      selectedBrand: null,
      lenses: [],
      selectedLens: null,
      selectedLensName: null,
      lensTypes: [],
      selectedLensType: null,
      selectedLensTypeName: null,
      products: [],
      selectedProduct: null,
      selectedProductName: null,
      paramsWidthDifferent: false,
      needHelp: false,
      receipt: null,
      productParamsDefault: {
        first: [],
        second: [],
        axis: [],
        all: [],
      },
      productParamsRight: Object.assign({}, this.productParamsDefault),
      productParamsLeft: Object.assign({}, this.productParamsDefault),
      productParamRightSphere: null,
      productParamLeftSphere: null,
      productParamRightCylinder: null,
      productParamLeftCylinder: null,
      productParamRightAxis: null,
      productParamLeftAxis: null,
      productParamRightDistance: null,
      productParamLeftDistance: null,
      productPrice: null,
      leftLensPrice: null,
      rightLensPrice: null,
      finalPrice: null,
      paramsWidth: null,
      paramsWidthRight: null,
      paramsWidthLeft: null,
      activeSubmit: false,

      typeImages: {
        'Стандартні': '1.png',
        'Блокування синього світла': '2.png',
        'Фотохромні': '3.png',
        'Сонцезахисні': '4.png',
        'Водійські': '5.png',
        'Прогресиви/Біфокали': '6.png',
      },

      modalOpen: false,
      modalContent: '',
    }
  },
  methods: {
    setStage(stage) {
      if (stage === 2 && this.selectedBrand == null) {
        return;
      } else if (stage === 3 && this.selectedLens === null) {
        return;
      } else if (stage === 4 && this.selectedLensType === null) {
        return;
      } else if (stage === 5 && this.selectedProduct === null) {
        return;
      }
      this.currentStage = stage;
    },
    getStageClass(stage) {
      let classes = ['pres-item'];
      if (stage === 2 && this.selectedBrand == null) {
        classes.push('pres-item-inactive')
      } else if (stage === 3 && this.selectedLens === null) {
        classes.push('pres-item-inactive')
      } else if (stage === 4 && this.selectedLensType === null) {
        classes.push('pres-item-inactive')
      } else if (stage === 5 && this.selectedProduct === null) {
        classes.push('pres-item-inactive')
      }
      return classes.join(' ');
    },
    closePopup() {
      this.fullScreenModal = false;
    },
    async initData() {
      this.isLoading = true;
      await axios
          .get(
              window.location.origin + '/product_vue/initSelectFull',
              {
                params: {
                  productId: this.productId,
                }
              }
          )
          .then(response => {
                this.brands = response.data.brands;
                this.image = response.data.image;
                this.productPrice = response.data.price;
                if (this.brands.length === 1) {
                  this.selectedBrand = this.brands[0].id;
                  this.selectedBrandName = this.brands[0].name;
                  this.setStage(2);
                }
                this.recalculateFinalPrice();
                this.isLoading = false;
              }
          );
    },
    selectBrand(brand) {
      this.selectedBrand = brand.id;
      this.selectedBrandName = brand.name;
      this.setStage(2);
    },
    brandsClasses(brand) {
      if (brand.id === this.selectedBrand) {
        return ['brand__item', 'brand__itemSelected'];
      } else {
        return ['brand__item', 'brand__itemUnselected'];
      }
    },
    lensesClasses(lens) {
      if (lens.id === this.selectedLens) {
        return ['lense__item', 'brand-item-selected'];
      } else {
        return ['lense__item', 'brand-item-unselected'];
      }
    },
    selectLens(lens) {
      this.selectedLens = lens.id;
      this.selectedLensName = lens.value;
      this.setStage(3);
    },
    lensTypeClasses(lensType) {
      if (lensType.id === this.selectedLensType) {
        return ['lensTypeBlock__item', 'brand-item-selected'];
      } else {
        return ['lensTypeBlock__item', 'brand-item-unselected'];
      }
    },
    selectLensType(lensType) {
      this.selectedLensType = lensType.id;
      this.selectedLensTypeName = lensType.value;
      this.setStage(4);
    },
    productClasses(product) {
      if (product.id === this.selectedProduct) {
        return ['lensType__item', 'lensType__itemSelected'];
      } else {
        return ['lensType__item', 'lensType__itemUnselected'];
      }
    },
    selectProduct(product) {
      this.selectedProduct = product.id;
      this.selectedProductName = product.title;
      this.setStage(5);
    },
    updateSphereRight(sphere) {
      this.productParamRightSphere = sphere;
    },
    updateSphereLeft(sphere) {
      this.productParamLeftSphere = sphere;
    },
    updateCylinderRight(cylinder) {
      this.productParamRightCylinder = cylinder;
    },
    clearValuesRight() {
      this.productParamRightCylinder = null;
      this.productParamRightSphere = null;
      this.productParamRightAxis = null;
    },
    updateCylinderLeft(cylinder) {
      this.productParamLeftCylinder = cylinder;
    },
    updateAxisRight(axis) {
      this.productParamRightAxis = axis;
    },
    updateAxisLeft(axis) {
      this.productParamLeftAxis = axis;
    },
    clearValuesLeft() {
      this.productParamLeftCylinder = null;
      this.productParamLeftSphere = null;
      this.productParamLeftAxis = null;
    },
    async updateParams(eye) {
      this.isLoading = true;
      let sphere = null;
      let cylinder = null;
      if (eye === 'right') {
        sphere = this.productParamRightSphere;
        cylinder = null;
      } else {
        sphere = this.productParamLeftSphere;
        cylinder = null;
      }
      await axios
          .get(
              window.location.origin + '/product_vue/productParams',
              {
                params: {
                  selectedProduct: this.selectedProduct,
                  sphere: sphere,
                  cylinder: cylinder,
                }
              }
          )
          .then(response => {
            if (eye === 'right') {
              this.productParamsRight = response.data.productParams;
            } else {
              this.productParamsLeft = response.data.productParams;
            }
            this.recalculateFinalPrice();
            this.isLoading = false;
          });
    },
    clearParams() {
      this.productParamRightSphere = null;
      this.productParamLeftSphere = null;
      this.productParamRightCylinder = null;
      this.productParamLeftCylinder = null;
      this.productParamRightAxis = null;
      this.productParamLeftAxis = null;
      this.productParamsRight = Object.assign({}, this.productParamsDefault);
      this.productParamsLeft = Object.assign({}, this.productParamsDefault);
    },
    readReceipt(event) {
      let reader = new FileReader();
      let file = event.target.files[0];
      if (file.size > 1024 * 1024 * 50) {
        alert('Розмір файлу не повинен перевищувати 50МБ');
        this.clearRecept();
        return;
      }
      if (!['image/png', 'image/jpeg', 'image/svg'].includes(file['type'])) {
        alert('Файл може бути лише наступних форматів: PNG, JPG, SVG');
        this.clearRecept();
        return;
      }

      reader.readAsDataURL(file);
      reader.onload = () => {
        this.receipt = {
          fileName: file.name,
          file: file,
          FileReader: reader.result,
        };
        this.recalculateFinalPrice();
      };
    },
    clearRecept() {
      this.receipt = null;
      this.$refs.fileupload.value = null;
      this.recalculateFinalPrice();
    },
    recalculateFinalPrice() {
      this.finalPrice = this.productPrice;
      let secondPrice = 0;
      if (this.selectedProduct) {
        this.products.forEach(product => {
          if (product.id === this.selectedProduct) {
            secondPrice = product.price;
          }
        })
      }
      // calculate eft eye params
      let leftEyePrice = secondPrice;
      if (this.productParamLeftSphere && this.productParamLeftCylinder && !this.receipt) {
        this.productParamsRight.all.forEach(property => {
          if (
              property.first === this.productParamLeftSphere
              && property.second === this.productParamLeftCylinder
          ) {
            leftEyePrice = parseFloat(property.price);
          }
        });
      }
      // calculate right eye params
      let rightEyePrice = secondPrice;
      if (this.productParamRightSphere && this.productParamRightCylinder && !this.receipt) {
        this.productParamsRight.all.forEach(property => {
          if (
              property.first === this.productParamRightSphere
              && property.second === this.productParamRightCylinder
          ) {
            rightEyePrice = parseFloat(property.price);
          }
        });
      }
      this.leftLensPrice = leftEyePrice;
      this.rightLensPrice = rightEyePrice;
      this.finalPrice = this.productPrice + leftEyePrice + rightEyePrice;
      if (
          this.selectedProduct
          &&
          ((
              this.productParamLeftSphere
              && this.productParamLeftCylinder
              && this.productParamRightSphere
              && this.productParamRightCylinder
          ) || this.receipt)
      ) {
        this.activeSubmit = true;
      } else {
        this.activeSubmit = false;
      }
    },
    async submitProduct() {
      this.isLoading = true;
      let formData = new FormData;
      let cmsToken = document.querySelector("[name='cms_token']");
      formData.append('cms_token', cmsToken.value);
      formData.append('productId', this.productId);
      formData.append('selectedProduct', this.selectedProduct);

      formData.append('productParamRightSphere', this.productParamRightSphere);
      formData.append('productParamRightCylinder', this.productParamRightCylinder);
      formData.append('productParamRightAxis', this.productParamRightAxis);
      formData.append('productParamRightDistance', this.productParamRightDistance);

      formData.append('productParamLeftSphere', this.productParamLeftSphere);
      formData.append('productParamLeftCylinder', this.productParamLeftCylinder);
      formData.append('productParamLeftAxis', this.productParamLeftAxis);
      formData.append('productParamLeftDistance', this.productParamLeftDistance);

      formData.append('paramsWidthDifferent', this.paramsWidthDifferent);
      formData.append('paramsWidth', this.paramsWidth);
      formData.append('paramsWidthRight', this.paramsWidthRight);
      formData.append('paramsWidthLeft', this.paramsWidthLeft);

      formData.append('needHelp', this.needHelp);
      if (this.receipt) {
        formData.append('receipt', this.receipt.file);
      }
      await axios
          .post(
              window.location.origin + '/product_vue/addLensToCart',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          )
          .then(async response => {
            this.selectedBrand = null;
            this.selectedBrandName = null;
            this.selectedLensType = null;
            this.lensTypes = [];
            this.selectedProduct = null;
            this.products = [];
            this.clearParams();
            this.recalculateFinalPrice();
            this.isLoading = false;
            let url = new URL(window.location.href);
            url.searchParams.append('showCart', 'true');
            window.location.href = url.href;
          })
          .catch(
              error => {
                alert(error);
                this.isLoading = false;
              }
          );
    },
    rangeDistanceSame() {
      let list = [];
      for (let i = 44; i <= 78; i++) {
        list.push(i);
      }
      return list;
    },
    rangeDistanceDifferent() {
      let list = [];
      for (let i = 22; i <= 40; i++) {
        list.push(i);
      }
      return list;
    },
    lensTypeImage(value) {
      return '/application/modules/product_vue/assets/images/' + this.typeImages[value];
    },
    async popup(type) {

      await axios
          .get(window.location.origin + '/product_vue/getPage', {params: {page: type}})
          .then(response => {
            this.modalOpen = true;
            this.modalContent = response.data;
          });

    },
  },
  watch: {
    async selectedBrand(brandId) {
      this.isLoading = true;
      await axios
          .get(window.location.origin + '/product_vue/getLens', {params: {brandId: brandId}})
          .then(response => {
                this.lenses = response.data.lenses;
                this.selectedLensType = null;
                this.lensTypes = [];
                this.selectedProduct = null;
                this.products = [];
                this.clearParams();
                this.recalculateFinalPrice();
                this.isLoading = false;
              }
          );
    },
    async selectedLens() {
      this.isLoading = true;
      await axios
          .get(
              window.location.origin + '/product_vue/getLensTypes',
              {
                params: {
                  brandId: this.selectedBrand,
                  lensId: this.selectedLens,
                }
              }
          )
          .then(response => {
                this.lensTypes = response.data.lensTypes;
                this.selectedLensType = null;
                this.selectedProduct = null;
                this.products = [];
                this.clearParams();
                this.recalculateFinalPrice();
                this.isLoading = false;
              }
          );
    },
    async selectedLensType() {
      this.isLoading = true;
      await axios
          .get(
              window.location.origin + '/product_vue/getProducts',
              {
                params: {
                  brandId: this.selectedBrand,
                  lensId: this.selectedLens,
                  lensType: this.selectedLensType,
                }
              }
          )
          .then(response => {
                this.products = response.data.products;
                this.isLoading = false;
                this.selectedProduct = null;
                this.clearParams();
                this.recalculateFinalPrice();
                this.isLoading = false;
              }
          );
    },
    async selectedProduct(selectedProduct) {
      this.isLoading = true;
      this.clearParams();
      await axios
          .get(
              window.location.origin + '/product_vue/productParams',
              {
                params: {
                  selectedProduct: selectedProduct,
                }
              }
          )
          .then(response => {
                this.productParamsRight = response.data.productParams;
                this.productParamsLeft = response.data.productParams;
                this.recalculateFinalPrice();
                this.isLoading = false;
              }
          );
    },
    productParamRightSphere() {
      this.updateParams('right');
    },
    productParamLeftSphere() {
      this.updateParams('left');
    },
    productParamRightCylinder() {
      this.updateParams('right');
    },
    productParamLeftCylinder() {
      this.updateParams('left');
    },
    modalOpen(value) {
      if (value) {
        document.documentElement.style.overflow = 'hidden';
        document.body.scroll = "no";
      } else {
        document.documentElement.style.overflow = 'auto';
        document.body.scroll = "yes";
      }
    },
    fullScreenModal(value) {
      if (value === true) {
        document.querySelector('html')
            .style
            .overflow = 'hidden';
      } else {
        document.querySelector('html')
            .style
            .overflow = 'auto';
      }
    }
  }
}
</script>

<template>
  <a @click="fullScreenModal = true" class="btn btn-default btn-lg">
    <span>Встановити лінзи</span>
  </a>
  <div class="wrapper" v-if="fullScreenModal">
    <div class="centralPart">
      <div class="leftPart">
        <div class="price-desc-diag-area">
          <div class="price-desc-inner">
            <div class="glass-img-wrap">
              <img :src="domain + 'uploads/shop/products/main/' + image" alt="">
            </div>
            <div class="inner-box">
              <table class="priceData">
                <tr>
                  <td class="priceDataName">
                    Вартість товару:
                  </td>
                  <td class="priceDataValue">
                    <product-price :price="productPrice"/>
                  </td>
                </tr>
                <tr v-if="rightLensPrice > 0">
                  <td class="priceDataName">
                    Вартість лінзи для правого ока:
                  </td>
                  <td class="priceDataValue">
                    <product-price :price="rightLensPrice"/>
                  </td>
                </tr>
                <tr v-if="leftLensPrice > 0">
                  <td class="priceDataName">
                    Вартість лінзи для лівого ока:
                  </td>
                  <td class="priceDataValue">
                    <product-price :price="leftLensPrice"/>
                  </td>
                </tr>
                <tr>
                  <td class="priceDataName">
                    Сумма:
                  </td>
                  <td class="priceDataValue">
                    <product-price :price="finalPrice"/>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="rightPart">
        <div class="rightPartHeaderWrapper">
          <h2>Оберіть лінзи для окулярів</h2>
          <div @click="closePopup()" class="close">X</div>
        </div>
        <a @click="currentStage = currentStage - 1" v-show="[2,3,4,5].includes(currentStage)" class="goBack">
          &#10094; Повернутися
        </a>
        <ul class="rightPartList">
          <li class="item-box" @click="setStage(1)" v-show="![2,3,4,5].includes(currentStage)">
            <div :class="getStageClass(1)">
              Оберіть бренд
              <div v-if="selectedBrand"> - {{ selectedBrandName }}</div>
              <div v-if="currentStage === 1">
                <!-- SELECT BRAND -->
                <div class="group-wrapper">
                  <div class="group-body">
                    <div v-for="brand in brands" @click.stop="selectBrand(brand)" class="brands__item">
                      <div :class="brandsClasses(brand)">
                        <img :src="domain + 'uploads/shop/brands/' + brand.image" alt="">
                        <div v-html="brand.description"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="item-box" @click="setStage(2)" v-show="![3,4,5].includes(currentStage)">
            <div :class="getStageClass(2)">
              Оберіть лінзи
              <div v-if="selectedLens"> - {{ selectedLensName }}</div>
              <div v-if="currentStage === 2">
                <div v-if="selectedBrand" class="group-body">
                  <!-- SELECT BRAND -->
                  <div class="group-wrapper">
                    <div class="group-body">
                      <div v-for="lens in lenses" @click.stop="selectLens(lens)">
                        <div :class="lensesClasses(lens)">
                          <div class="lense__itemName">
                            <div>{{ lens.value }}</div>
                          </div>
                          <div class="lense__itemDescription">{{ lens.description }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="group-body">
                  <div class="message message--info">
                    Спочатку виберіть бренд лінзи
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="item-box" @click="setStage(3)" v-show="![4,5].includes(currentStage)">
            <div :class="getStageClass(3)">
              Оберіть тип лінзи
              <div v-if="selectedLensType"> - {{ selectedLensTypeName }}</div>
              <div v-if="currentStage === 3">
                <div v-if="selectedLens" class="group-body">
                  <div class="group-wrapper">
                    <div class="group-body">
                      <div v-for="lensType in lensTypes" @click.stop="selectLensType(lensType)">
                        <div :class="lensTypeClasses(lensType)">
                          <div class="lensTypeBlock__itemName">
                            <img :src="lensTypeImage(lensType.value)" alt="">
                          </div>
                          <div class="lensTypeBlock__itemDescription">
                            <div style="font-weight:bold;">{{ lensType.value }}</div>
                            <div>{{ lensType.description }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="group-body">
                  <div class="message message--info">
                    Спочатку виберіть лінзи
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="item-box" @click="setStage(4)" v-show="![5].includes(currentStage)">
            <div :class="getStageClass(4)">
              <div class="helpText">Оберіть підходящі лінзи</div>
              <div v-if="selectedProduct"> - {{ selectedProductName }}</div>
              <div v-if="currentStage === 4">
                <div v-if="selectedLensType" class="group-body">
                  <div class="lensType__items">
                    <div v-for="product in products" @click.stop="selectProduct(product)" class="lensType__itemWrapper">
                      <div :class="productClasses(product)">
                        <div class="lensType__image">
                          <img :src="domain + 'uploads/shop/products/medium/' + product.image" alt="">
                        </div>
                        <div class="lensType__properties">
                          <div class="productTitle">{{ product.title }}</div>
                          <table class="productProperties">
                            <tr v-for="property in product.main_properties" class="productPropertyRow">
                              <td>{{ property.property_name }}</td>
                              <td>{{ property.property_value }}</td>
                            </tr>
                          </table>
                        </div>
                        <div class="lensType__price">
                          <div class="ProductPriceWrapper">
                            <div style="font-weight:bold;">
                              від <span class="productPrice" v-html="product.price_from"/>
                            </div>
                            <div class="productPriceInfo" style="font-weight:bold;">за пару</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="group-body">
                  <div class="message message--info">
                    Спочатку оберіть тип лінзи
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="item-box" @click="setStage(5)">
            <div :class="getStageClass(5)">
              Введіть свої значення рецепту
              <div v-if="currentStage === 5">
                <div v-if="selectedProduct" class="group-body">
                  <product-params-selector
                      :eye="'right'"
                      :receipt="receipt"
                      :productParams="productParamsRight"
                      :productParamSphere="productParamRightSphere"
                      :productParamCylinder="productParamRightCylinder"
                      :productParamAxis="productParamRightAxis"
                      :productParams2="productParamsLeft"
                      :productParamSphere2="productParamLeftSphere"
                      :productParamCylinder2="productParamLeftCylinder"
                      :productParamAxis2="productParamLeftAxis"
                      @updateSphere="updateSphereRight"
                      @updateCylinder="updateCylinderRight"
                      @updateAxis="updateAxisRight"
                      @clearValues="clearValuesRight"
                      @updateSphere2="updateSphereLeft"
                      @updateCylinder2="updateCylinderLeft"
                      @updateAxis2="updateAxisLeft"
                      @clearValues2="clearValuesLeft"
                  />

                  <!--                  <product-params-selector-->
                  <!--                      :eye="'left'"-->
                  <!--                      :receipt="receipt"-->
                  <!--                      :productParams="productParamsLeft"-->
                  <!--                      :productParamSphere="productParamLeftSphere"-->
                  <!--                      :productParamCylinder="productParamLeftCylinder"-->
                  <!--                      :productParamAxis="productParamLeftAxis"-->
                  <!--                      @updateSphere="updateSphereLeft"-->
                  <!--                      @updateCylinder="updateCylinderLeft"-->
                  <!--                      @updateAxis="updateAxisLeft"-->
                  <!--                      @clearValues="clearValuesLeft"-->
                  <!--                  />-->


                  <div class="paramsWrapperItem paramsWrapperItemWidth">
                    <div>&nbsp</div>
                    <label>
                      <input type="checkbox" v-model="paramsWidthDifferent">
                      Для різних очей
                    </label>
                  </div>

                  <table border class="tableParams" v-if="paramsWidthDifferent === false">
                    <tr>
                      <td class="bg-head">
                        <div>PD</div>
                        <div>Відстань між зінницями</div>
                      </td>
                      <td>
                        <select v-model="paramsWidth">
                          <option v-for="distance in rangeDistanceSame()">
                            {{ distance }}
                          </option>
                        </select>
                      </td>
                    </tr>
                  </table>

                  <table border class="tableParams" v-if="paramsWidthDifferent === true">
                    <tr>
                      <td class="bg-head">
                        <div>PD (OD)</div>
                        <div>Відстань між зінницями (справа)</div>
                      </td>
                      <td>
                        <select v-model="paramsWidthRight">
                          <option v-for="distance in rangeDistanceDifferent()">
                            {{ distance }}
                          </option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td class="bg-head">
                        <div>PD (OS)</div>
                        <div>Відстань між зінницями (зліва)</div>
                      </td>
                      <td>
                        <select v-model="paramsWidthLeft">
                          <option v-for="distance in rangeDistanceDifferent()">
                            {{ distance }}
                          </option>
                        </select>
                      </td>
                    </tr>
                  </table>

                  <br><br>
                  <div class="helpBlock">

                    <div>
                      Завантажити рецепт (фото):
                    </div>

                    <div>
                      <input
                          type="file"
                          accept="image/*"
                          @input="readReceipt"
                          ref="fileupload">
                    </div>
                  </div>
                  <div v-if="receipt" class="helpBlockResult">
                    Прикріплений файл рецепту: {{ receipt.fileName }}
                    <button
                        type="button"
                        @click="clearRecept"
                        style="color:blue;"
                    >
                      Відкріпити
                    </button>
                    <div>Якщо в рецепті є усі неохібні дані - в такому випадку поля нижче не обов'язкові до заповнення
                    </div>
                  </div>
                  <div v-else>
                    Якщо ви завантажете файл - в такому випадку поля вище не обов'язкові до заповнення
                  </div>


                </div>
                <div v-else class="group-body">
                  <div class="message message--info">
                    Спочатку оберіть лінзу
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="glass-process-bottom-area">
      <div class="subtotal-wrap">
        <div class="subtotal-inner">
          <product-price :price="finalPrice"/>
        </div>
        <div class="addToCartWrapper">
          <div v-if="activeSubmit">
            <button class="btn btn-default btn-lg" type="button" @click="submitProduct">
              <span>Додати в корзину</span>
            </button>
          </div>
          <div style="display: flex;justify-items: center;justify-content: center;">
            <div v-if="activeSubmit === false" class="productError message message--error">
              Вам необхідно обрати лінзу, сферу та циліндр для обох очей, щоб мати можливість замовити комплект
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(248, 248, 248, 1);
  backdrop-filter: blur(10px);
  padding: 0 0 87px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow-y: auto;
  transition: all .3s;
  display: flex;
  justify-content: center;
}

.centralPart {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 1300px;
  overflow: hidden;
}

@media all and (max-width: 950px) {
  .centralPart {
    flex-direction: column;
    overflow: scroll;
  }
}

.leftPart {
  width: 50%;
}

@media all and (max-width: 950px) {
  .leftPart {
    display: none;
  }
}

.rightPart {
  width: 100%;
  height: 100%;
  padding: 50px;
  overflow: hidden;
}

@media all and (max-width: 950px) {
  .rightPart {
    padding: 5px;
  }
}

.rightPartList {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.item-box {
  width: 100%;
  border: 1px solid #fff;
  border-radius: 6px;
  margin-bottom: 12px;
}


.pres-item {
  background: #fff;
  padding: 16px 14px;
  cursor: pointer;
  position: relative;
}

.pres-item-inactive {
  pointer-events: none;
  background: rgba(255, 255, 255, 0.27) !important;
  color: rgba(128, 128, 128, 0.61);
}

/***/
.group-header {
  color: #333;
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Bebas Neue Cyrillic", sans-serif;
  line-height: 1;
  padding-bottom: 12px;
  border-bottom: 2px solid #007eef;
}

.brand-item {
  min-height: 100px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 5px;
  background-color: #fff;
  transition: .3s all ease;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.brand-item-selected {
  box-shadow: 0 0 10px rgb(0 126 239 / 20%);
  border: 1px solid #007eef;
  background-color: #007eef;
  color: white;
}

.productPriceWrapper {
  font-weight: bold;
}

.brand-item-selected .productPrice {
  color: white !important;
}

.brand-item-unselected {
  border: 1px solid #e0e0e0;
}

.brand-item:hover {
  background-color: #e0e0e0;
}

.lensType__itemWrapper :hover {
  background-color: #e0e0e0;
}

/**
BOTTOM
 */
.glass-process-bottom-area {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 87px;
  z-index: 102;
  display: flex;
  justify-content: center;
  align-content: center;
  background: #fff;
  box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, .08);
  transition: all .3s;
}

.subtotal-wrap {
  max-width: 1300px;
  padding-right: 3.5416666667vw;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
}

.subtotal-inner {
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media all and (max-width: 950px) {
  .subtotal-inner {
    display: none;
  }
}

/**
LEFT SIDE
 */
.price-desc-diag-area {
  background: #fff;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.price-desc-inner {
  height: calc(100vh - 90px);
  position: sticky;
  top: 0;
  width: 534px;
  padding-right: 3.5416666667vw;
  overflow-y: auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.glass-img-wrap {
  padding-top: 9.375vw;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.inner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 330px;
  padding-bottom: 1.25vw;
  box-sizing: border-box;
}

@media all and (max-width: 950px) {
  .inner-box {
    display: none;
  }
}


/**  */
.brands__item {
  display: flex;
  flex-direction: column;
  border: 1px solid #E5E5E5;
}

.brand__item > img {
  max-height: 150px;
  max-width: 150px;
}

.brand__item {
  display: flex;
  align-items: center;
}

.lense__item {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.lense__item .lense__itemName {
  width: 100%;
  aspect-ratio: 6/3;
  max-height: 150px;
  max-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lense__item .lense__itemName > div {
  text-align: center;
}

.lensType__items {
  display: flex;
  flex-direction: column;
}

.lensType__item {
  display: flex;
  padding: 5px;
  margin: 5px;
  border: 1px solid #E5E5E5;
  justify-content: space-between;
}

.lensType__image {
  width: 20%;
  padding: 5px;
  aspect-ratio: auto;
}

.productTitle {
  font-size: 1.2rem;
  font-weight: bold;
}

.lensType__properties {
  width: 60%;
  padding: 5px;
  aspect-ratio: auto;
}

.lensType__price {
  width: 20%;
  padding: 5px;
}

.lensTypeBlock__item {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.lensTypeBlock__item .lensTypeBlock__itemName {
  width: 100%;
  aspect-ratio: 4/3;
  max-height: 150px;
  max-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lensTypeBlock__item .lensTypeBlock__itemName > div {
  text-align: center;
}


.paramsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d0d4db;
}

@media all and (max-width: 950px) {
  .paramsWrapper {
    flex-direction: column;
  }
}

@media all and (min-width: 950px) {
  .paramsWrapper {
    flex-direction: row;
  }
}

.paramsWrapper .paramsWrapperItem {
  flex: 1;
  padding: 5px;
}

@media all and (max-width: 950px) {
  .paramsWrapper .paramsWrapperItem {
    width: 100%;
  }
}

@media all and (min-width: 950px) {
  .paramsWrapper .paramsWrapperItem {
    width: auto;
  }
}

.addToCartWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.rightPartHeaderWrapper {
  display: flex;
  justify-content: space-between;
}

.rightPartHeaderWrapper h2 {
  font-size: 2rem;
}

.rightPartHeaderWrapper .close {
  display: block;
  padding: 5px;
  margin: 5px;
  border: 1px solid #d0d4db;
  background-color: #ffffff;
  cursor: pointer;
}

.priceData td {
  padding: 10px;
}

.goBack {
  cursor: pointer;
  font-weight: bold;
}

.goBack:hover {
  cursor: pointer;
  background-color: #ffffff;
}

.helpText {
  font-weight: bold;
}

.brand-item-unselected:hover {
  cursor: pointer;
  background-color: #d0d4db;
}

.bbrand__itemUnselected:hover {
  cursor: pointer;
  background-color: #d0d4db;
}
</style>