<template>
  <ProductVueFullScpeen/>
<!--    <div style="position: relative">-->
<!--        <div class="modal-overlay" v-if="modalOpen" @click="modalOpen = !modalOpen">-->
<!--            <div class="modal-content" @click.stop>-->
<!--                <div style="display: flex;justify-content: space-between">-->
<!--                    <div>-->
<!--                    </div>-->
<!--                    <svg class="modal__header-close svg-icon" role="button" @click="modalOpen = !modalOpen">-->
<!--                        <use xlink:href="/templates/megashop/_img/sprite.svg#svg-icon__close"></use>-->
<!--                    </svg>-->
<!--                </div>-->
<!--                <div class="typo" v-html="modalContent"/>-->
<!--            </div>-->
<!--        </div>-->

<!--        <vue-loading v-model:active="isLoading">-->
<!--            <loading-animation/>-->
<!--        </vue-loading>-->
<!--        &lt;!&ndash; SELECT BRAND &ndash;&gt;-->
<!--        <div class="group-wrapper">-->
<!--            <div class="group-header">Оберіть бренд лінз</div>-->
<!--            <div class="group-body">-->
<!--                <swiper-->
<!--                        :space-between="10"-->
<!--                        :width="220"-->
<!--                        :short-swipes="true"-->
<!--                >-->
<!--                    <swiper-slide v-for="brand in brands" @click="selectBrand(brand)">-->
<!--                        <div :class="brandsClasses(brand)">-->
<!--                            <img :src="'https://prooko.com.ua/uploads/shop/brands/' + brand.image" alt="">-->
<!--                        </div>-->
<!--                    </swiper-slide>-->
<!--                </swiper>-->
<!--            </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; SELECT LENS &ndash;&gt;-->
<!--        <div class="group-wrapper">-->
<!--            <div class="group-header">Оберіть лінзи</div>-->
<!--            <div v-if="selectedBrand" class="group-body">-->
<!--                <swiper-->
<!--                        :space-between="10"-->
<!--                        :width="220"-->
<!--                        :short-swipes="true"-->
<!--                >-->
<!--                    <swiper-slide v-for="lens in lenses" @click="selectLens(lens)">-->
<!--                        <div :class="lensesClasses(lens)">-->
<!--                            {{ lens.value }}-->
<!--                        </div>-->
<!--                    </swiper-slide>-->
<!--                </swiper>-->
<!--            </div>-->
<!--            <div v-else class="group-body">-->
<!--                <div class="message message&#45;&#45;info">-->
<!--                    Спочатку виберіть бренд лінзи-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; SELECT LENS TYPE &ndash;&gt;-->
<!--        <div class="group-wrapper">-->
<!--            <div class="group-header">Оберіть тип лінзи</div>-->
<!--            <div v-if="selectedLens" class="group-body">-->
<!--                <swiper-->
<!--                        :space-between="10"-->
<!--                        :width="220"-->
<!--                        :short-swipes="true"-->
<!--                >-->
<!--                    <swiper-slide v-for="lensType in lensTypes" @click="selectLensType(lensType)">-->
<!--                        <div :class="lensTypeClasses(lensType)">-->
<!--                            <div style="height: 190px;width:190px;">-->
<!--                                <img :src="lensTypeImage(lensType.value)" alt="">-->
<!--                            </div>-->
<!--                            <div style="height:50px;">{{ lensType.value }}</div>-->
<!--                        </div>-->
<!--                    </swiper-slide>-->
<!--                </swiper>-->
<!--            </div>-->
<!--            <div v-else class="group-body">-->
<!--                <div class="message message&#45;&#45;info">-->
<!--                    Спочатку виберіть лінзи-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; SELECT PRODUCT &ndash;&gt;-->
<!--        <div class="group-wrapper">-->
<!--            <div class="group-header">Оберіть підходящі лінзи</div>-->
<!--            <div v-if="selectedLensType" class="group-body">-->
<!--                <swiper-->
<!--                        :space-between="10"-->
<!--                        :width="220"-->
<!--                        :short-swipes="true"-->
<!--                >-->
<!--                    <swiper-slide v-for="product in products" @click="selectProduct(product)">-->
<!--                        <div :class="productClasses(product)">-->
<!--                            <img :src="'https://prooko.com.ua/uploads/shop/products/medium/' + product.image" alt="">-->
<!--                            <div class="productTitle">{{ product.title }}</div>-->
<!--                            <div class="ProductPriceWrapper">-->
<!--                                <div>-->
<!--                                    від <span class="productPrice" v-html="product.price_from"/>-->
<!--                                </div>-->
<!--                                <div class="productPriceInfo">за пару</div>-->
<!--                            </div>-->
<!--                            <table class="productProperties">-->
<!--                                <tr v-for="property in product.main_properties" class="productPropertyRow">-->
<!--                                    <td>{{ property.property_name }}</td>-->
<!--                                    <td>{{ property.property_value }}</td>-->
<!--                                </tr>-->
<!--                            </table>-->
<!--                        </div>-->
<!--                    </swiper-slide>-->
<!--                </swiper>-->
<!--            </div>-->
<!--            <div v-else class="group-body">-->
<!--                <div class="message message&#45;&#45;info">-->
<!--                    Спочатку оберіть тип лінзи-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; SELECT PARAMS &ndash;&gt;-->
<!--        <div class="group-wrapper">-->
<!--            <div class="group-header">-->
<!--                Введіть свої значення для скла-->
<!--            </div>-->
<!--            <div v-if="selectedProduct" class="group-body">-->
<!--                <div class="helpBlock">-->

<!--                    <div>-->
<!--                        Завантажити рецепт (фото):-->
<!--                    </div>-->

<!--                    <div>-->
<!--                        <input-->
<!--                                type="file"-->
<!--                                accept="image/*"-->
<!--                                @input="readReceipt"-->
<!--                                ref="fileupload">-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div v-if="receipt" class="helpBlockResult">-->
<!--                    Прикріплений файл рецепту: {{ receipt.fileName }}-->
<!--                    <button-->
<!--                            type="button"-->
<!--                            @click="clearRecept"-->
<!--                            style="color:blue;"-->
<!--                    >-->
<!--                        Відкріпити-->
<!--                    </button>-->
<!--                    <div>Якщо в рецепті є усі неохібні дані - в такому випадку поля нижче не обов'язкові до заповнення-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div v-else>-->
<!--                    Якщо ви завантажете файл - в такому випадку поля нижче не обов'язкові до заповнення-->
<!--                </div>-->


<!--                <product-params-selector-->
<!--                        :eye="'right'"-->
<!--                        :receipt="receipt"-->
<!--                        :productParams="productParamsRight"-->
<!--                        :productParamSphere="productParamRightSphere"-->
<!--                        :productParamCylinder="productParamRightCylinder"-->
<!--                        :productParamAxis="productParamRightAxis"-->
<!--                        @updateSphere="updateSphereRight"-->
<!--                        @updateCylinder="updateCylinderRight"-->
<!--                        @updateAxis="updateAxisRight"-->
<!--                        @clearValues="clearValuesRight"-->
<!--                />-->

<!--                <product-params-selector-->
<!--                        :eye="'left'"-->
<!--                        :receipt="receipt"-->
<!--                        :productParams="productParamsLeft"-->
<!--                        :productParamSphere="productParamLeftSphere"-->
<!--                        :productParamCylinder="productParamLeftCylinder"-->
<!--                        :productParamAxis="productParamLeftAxis"-->
<!--                        @updateSphere="updateSphereLeft"-->
<!--                        @updateCylinder="updateCylinderLeft"-->
<!--                        @updateAxis="updateAxisLeft"-->
<!--                        @clearValues="clearValuesLeft"-->
<!--                />-->

<!--                <div class="additionalParamsWrapper">-->
<!--                    <div v-if="paramsWidthDifferent === false" class="paramsWrapperItem paramsWrapperItemWidth">-->
<!--                        <div>Оберіть відстань між зінницями</div>-->
<!--                        <div>-->
<!--                            <select v-model="paramsWidth">-->
<!--                                <option v-for="distance in rangeDistanceSame()">-->
<!--                                    {{ distance }}-->
<!--                                </option>-->
<!--                            </select>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="paramsWrapperItem paramsWrapperItemWidth">-->
<!--                        <div>&nbsp</div>-->
<!--                        <label>-->
<!--                            <input type="checkbox" v-model="paramsWidthDifferent">-->
<!--                            Для різних очей-->
<!--                        </label>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="additionalParamsWrapper" v-if="paramsWidthDifferent === true">-->
<!--                    <div class="paramsWrapperItem paramsWrapperItemWidth">-->
<!--                        <div>Для правого</div>-->
<!--                        <div>-->
<!--                            <select v-model="paramsWidthRight">-->
<!--                                <option v-for="distance in rangeDistanceDifferent()">-->
<!--                                    {{ distance }}-->
<!--                                </option>-->
<!--                            </select>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="paramsWrapperItem paramsWrapperItemWidth">-->
<!--                        <div>Для лівого</div>-->
<!--                        <div>-->
<!--                            <select v-model="paramsWidthLeft">-->
<!--                                <option v-for="distance in rangeDistanceDifferent()">-->
<!--                                    {{ distance }}-->
<!--                                </option>-->
<!--                            </select>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--            </div>-->
<!--            <div v-else class="group-body">-->
<!--                <div class="message message&#45;&#45;info">-->
<!--                    Спочатку оберіть лінзу-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="popupLinks">-->
<!--            <button type="button" class="link link&#45;&#45;main" @click="popup(1)">Як виміряти відстань між зірницями?</button>-->
<!--            <button type="button" class="link link&#45;&#45;main" @click="popup(2)">Як заповнити рецепт?</button>-->
<!--        </div>-->

<!--        <table class="priceData">-->
<!--            <tr>-->
<!--                <td class="priceDataName">-->
<!--                    Вартість товару:-->
<!--                </td>-->
<!--                <td class="priceDataValue">-->
<!--                    <product-price :price="productPrice"/>-->
<!--                </td>-->
<!--            </tr>-->
<!--            <tr v-if="rightLensPrice > 0">-->
<!--                <td class="priceDataName">-->
<!--                    Вартість лінзи для правого ока:-->
<!--                </td>-->
<!--                <td class="priceDataValue">-->
<!--                    <product-price :price="rightLensPrice"/>-->
<!--                </td>-->
<!--            </tr>-->
<!--            <tr v-if="leftLensPrice > 0">-->
<!--                <td class="priceDataName">-->
<!--                    Вартість лінзи для лівого ока:-->
<!--                </td>-->
<!--                <td class="priceDataValue">-->
<!--                    <product-price :price="leftLensPrice"/>-->
<!--                </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--                <td class="priceDataName">-->
<!--                    Сумма:-->
<!--                </td>-->
<!--                <td class="priceDataValue">-->
<!--                    <product-price :price="finalPrice"/>-->
<!--                </td>-->
<!--            </tr>-->
<!--        </table>-->

<!--        <div>-->
<!--            <button class="btn btn-primary btn-lg" type="button" @click="submitProduct" :disabled="!activeSubmit">-->
<!--                <span>Додати в корзину</span>-->
<!--            </button>-->
<!--            <div v-if="activeSubmit === false" class="productError message message&#45;&#45;error">-->
<!--                Вам необхідно обрати лінзу, сферу та циліндр для обох очей, щоб мати можливість замовити комплект-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="spacer"/>-->

<!--        <div style="display: none">-->
<!--            {{ selectedBrand }} - {{ selectedLens }} - {{ selectedLensType }} - {{ selectedProduct }}-->
<!--            -&#45;&#45;-->
<!--            <br>-->
<!--            productParamRightSphere: {{ productParamRightSphere }} <br>-->
<!--            productParamLeftSphere: {{ productParamLeftSphere }}<br>-->
<!--            productParamRightCylinder: {{ productParamRightCylinder }} <br>-->
<!--            productParamLeftCylinder: {{ productParamLeftCylinder }}<br>-->
<!--            productParamRightAxis: {{ productParamRightAxis }} <br>-->
<!--            productParamLeftAxis: {{ productParamLeftAxis }}<br>-->
<!--            <br>-->
<!--            needHelp: {{ needHelp }}<br>-->
<!--            receipt: {{ receipt }}<br>-->
<!--            <br>-->
<!--            productPrice: {{ productPrice }}<br>-->
<!--            finalPrice: {{ finalPrice }}<br>-->
<!--            selectedProduct: {{ selectedProduct }}<br>-->
<!--            products: {{ products }}<br>-->
<!--        </div>-->

<!--    </div>-->
</template>

<script>
import axios from "axios";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import ProductParamsSelector from "./ProductParamsSelector.vue";
import ProductPrice from "./ProductPrice.vue";
import LoadingAnimation from "../LoadingAnimation.vue";
import ProductVueFullScpeen from "./ProductVueFullScpeen.vue";

export default {
    name: "ProductSelectFull",
    components: {
      ProductVueFullScpeen,
        LoadingAnimation,
        ProductPrice,
        ProductParamsSelector,
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            isLoading: true,
            brands: [],
            selectedBrand: null,
            lenses: [],
            selectedLens: null,
            lensTypes: [],
            selectedLensType: null,
            products: [],
            selectedProduct: null,
            paramsWidthDifferent: false,
            needHelp: false,
            receipt: null,
            productParamsDefault: {
                first: [],
                second: [],
                axis: [],
                all: [],
            },
            productParamsRight: Object.assign({}, this.productParamsDefault),
            productParamsLeft: Object.assign({}, this.productParamsDefault),
            productParamRightSphere: null,
            productParamLeftSphere: null,
            productParamRightCylinder: null,
            productParamLeftCylinder: null,
            productParamRightAxis: null,
            productParamLeftAxis: null,
            productParamRightDistance: null,
            productParamLeftDistance: null,
            productPrice: null,
            leftLensPrice: null,
            rightLensPrice: null,
            finalPrice: null,
            paramsWidth: null,
            paramsWidthRight: null,
            paramsWidthLeft: null,
            activeSubmit: false,

            typeImages: {
                'Стандартні': '1.pnп',
                'Блокування синього світла': '2.png',
                'Фотохромні': '3.png',
                'Сонцезахисні': '4.png',
                'Водійські': '5.png',
                'Прогресиви/Біфокали': '6.png',
            },

            modalOpen: false,
            modalContent: '',
        }
    },
    methods: {
        async initData() {
            this.isLoading = true;
            await axios
                .get(
                    window.location.origin + '/product_vue/initSelectFull',
                    {
                        params: {
                            productId: this.productId,
                        }
                    }
                )
                .then(response => {
                        this.brands = response.data.brands;
                        this.productPrice = response.data.price;
                        if (this.brands.length === 1) {
                            this.selectedBrand = this.brands[0].id;
                        }
                        this.recalculateFinalPrice();
                        this.isLoading = false;
                    }
                );
        },
        selectBrand(brand) {
            this.selectedBrand = brand.id;
        },
        brandsClasses(brand) {
            if (brand.id === this.selectedBrand) {
                return ['brand-item', 'brand-item-selected'];
            } else {
                return ['brand-item', 'brand-item-unselected'];
            }
        },
        lensesClasses(lens) {
            if (lens.id === this.selectedLens) {
                return ['brand-item', 'brand-item-selected'];
            } else {
                return ['brand-item', 'brand-item-unselected'];
            }
        },
        selectLens(lens) {
            this.selectedLens = lens.id;
        },
        lensTypeClasses(lensType) {
            if (lensType.id === this.selectedLensType) {
                return ['brand-item', 'brand-item-selected'];
            } else {
                return ['brand-item', 'brand-item-unselected'];
            }
        },
        selectLensType(lensType) {
            this.selectedLensType = lensType.id;
        },
        productClasses(product) {
            if (product.id === this.selectedProduct) {
                return ['brand-item', 'brand-item-selected'];
            } else {
                return ['brand-item', 'brand-item-unselected'];
            }
        },
        selectProduct(product) {
            this.selectedProduct = product.id;
        },
        updateSphereRight(sphere) {
            this.productParamRightSphere = sphere;
        },
        updateSphereLeft(sphere) {
            this.productParamLeftSphere = sphere;
        },
        updateCylinderRight(cylinder) {
            this.productParamRightCylinder = cylinder;
        },
        clearValuesRight() {
            this.productParamRightCylinder = null;
            this.productParamRightSphere = null;
        },
        updateCylinderLeft(cylinder) {
            this.productParamLeftCylinder = cylinder;
        },
        updateAxisRight(axis) {
            this.productParamRightAxis = axis;
        },
        updateAxisLeft(axis) {
            this.productParamLeftAxis = axis;
        },
        clearValuesLeft() {
            this.productParamLeftCylinder = null;
            this.productParamLeftSphere = null;
        },
        async updateParams(eye) {
            this.isLoading = true;
            let sphere = null;
            let cylinder = null;
            if (eye === 'right') {
                sphere = this.productParamRightSphere;
                cylinder = null;
            } else {
                sphere = this.productParamLeftSphere;
                cylinder = null;
            }
            await axios
                .get(
                    window.location.origin + '/product_vue/productParams',
                    {
                        params: {
                            selectedProduct: this.selectedProduct,
                            sphere: sphere,
                            cylinder: cylinder,
                        }
                    }
                )
                .then(response => {
                    if (eye === 'right') {
                        this.productParamsRight = response.data.productParams;
                    } else {
                        this.productParamsLeft = response.data.productParams;
                    }
                    this.recalculateFinalPrice();
                    this.isLoading = false;
                });
        },
        clearParams() {
            this.productParamRightSphere = null;
            this.productParamLeftSphere = null;
            this.productParamRightCylinder = null;
            this.productParamLeftCylinder = null;
            this.productParamRightAxis = null;
            this.productParamLeftAxis = null;
            this.productParamsRight = Object.assign({}, this.productParamsDefault);
            this.productParamsLeft = Object.assign({}, this.productParamsDefault);
        },
        readReceipt(event) {
            let reader = new FileReader();
            let file = event.target.files[0];
            if (file.size > 1024 * 1024 * 50) {
                alert('Розмір файлу не повинен перевищувати 50МБ');
                this.clearRecept();
                return;
            }
            if (!['image/png', 'image/jpeg', 'image/svg'].includes(file['type'])) {
                alert('Файл може бути лише наступних форматів: PNG, JPG, SVG');
                this.clearRecept();
                return;
            }

            reader.readAsDataURL(file);
            reader.onload = () => {
                this.receipt = {
                    fileName: file.name,
                    file: file,
                    FileReader: reader.result,
                };
                this.recalculateFinalPrice();
            };
        },
        clearRecept() {
            this.receipt = null;
            this.$refs.fileupload.value = null;
            this.recalculateFinalPrice();
        },
        recalculateFinalPrice() {
            this.finalPrice = this.productPrice;
            let secondPrice = 0;
            if (this.selectedProduct) {
                this.products.forEach(product => {
                    if (product.id === this.selectedProduct) {
                        secondPrice = product.price;
                    }
                })
            }
            // calculate eft eye params
            let leftEyePrice = secondPrice;
            if (this.productParamLeftSphere && this.productParamLeftCylinder && !this.receipt) {
                this.productParamsRight.all.forEach(property => {
                    if (
                        property.first === this.productParamLeftSphere
                        && property.second === this.productParamLeftCylinder
                    ) {
                        leftEyePrice = parseFloat(property.price);
                    }
                });
            }
            // calculate right eye params
            let rightEyePrice = secondPrice;
            if (this.productParamRightSphere && this.productParamRightCylinder && !this.receipt) {
                this.productParamsRight.all.forEach(property => {
                    if (
                        property.first === this.productParamRightSphere
                        && property.second === this.productParamRightCylinder
                    ) {
                        rightEyePrice = parseFloat(property.price);
                    }
                });
            }
            this.leftLensPrice = leftEyePrice;
            this.rightLensPrice = rightEyePrice;
            this.finalPrice = this.productPrice + leftEyePrice + rightEyePrice;
            if (
                this.selectedProduct
                &&
                ((
                    this.productParamLeftSphere
                    && this.productParamLeftCylinder
                    && this.productParamRightSphere
                    && this.productParamRightCylinder
                ) || this.receipt)
            ) {
                this.activeSubmit = true;
            } else {
                this.activeSubmit = false;
            }
        },
        async submitProduct() {
            this.isLoading = true;
            let formData = new FormData;
            let cmsToken = document.querySelector("[name='cms_token']");
            formData.append('cms_token', cmsToken.value);
            formData.append('productId', this.productId);
            formData.append('selectedProduct', this.selectedProduct);

            formData.append('productParamRightSphere', this.productParamRightSphere);
            formData.append('productParamRightCylinder', this.productParamRightCylinder);
            formData.append('productParamRightAxis', this.productParamRightAxis);
            formData.append('productParamRightDistance', this.productParamRightDistance);

            formData.append('productParamLeftSphere', this.productParamLeftSphere);
            formData.append('productParamLeftCylinder', this.productParamLeftCylinder);
            formData.append('productParamLeftAxis', this.productParamLeftAxis);
            formData.append('productParamLeftDistance', this.productParamLeftDistance);

            formData.append('paramsWidthDifferent', this.paramsWidthDifferent);
            formData.append('paramsWidth', this.paramsWidth);
            formData.append('paramsWidthRight', this.paramsWidthRight);
            formData.append('paramsWidthLeft', this.paramsWidthLeft);

            formData.append('needHelp', this.needHelp);
            if (this.receipt) {
                formData.append('receipt', this.receipt.file);
            }
            await axios
                .post(
                    window.location.origin + '/product_vue/addLensToCart',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then(async response => {
                    this.selectedBrand = null;
                    this.selectedLensType = null;
                    this.lensTypes = [];
                    this.selectedProduct = null;
                    this.products = [];
                    this.clearParams();
                    this.recalculateFinalPrice();
                    this.isLoading = false;
                    let url = new URL(window.location.href);
                    url.searchParams.append('showCart', 'true');
                    window.location.href = url.href;
                })
                .catch(
                    error => {
                        alert(error);
                        this.isLoading = false;
                    }
                );
        },
        rangeDistanceSame() {
            let list = [];
            for (let i = 44; i <= 78; i++) {
                list.push(i);
            }
            return list;
        },
        rangeDistanceDifferent() {
            let list = [];
            for (let i = 22; i <= 40; i++) {
                list.push(i);
            }
            return list;
        },
        lensTypeImage(value) {
            return '/application/modules/product_vue/assets/images/' + this.typeImages[value];
        },
        async popup(type) {

            await axios
                .get(window.location.origin + '/product_vue/getPage', {params: {page: type}})
                .then(response => {
                    this.modalOpen = true;
                    this.modalContent = response.data;
                });

        },
    },
    watch: {
        async selectedBrand(brandId) {
            this.isLoading = true;
            await axios
                .get(window.location.origin + '/product_vue/getLens', {params: {brandId: brandId}})
                .then(response => {
                        this.lenses = response.data.lenses;
                        this.selectedLensType = null;
                        this.lensTypes = [];
                        this.selectedProduct = null;
                        this.products = [];
                        this.clearParams();
                        this.recalculateFinalPrice();
                        this.isLoading = false;
                    }
                );
        },
        async selectedLens() {
            this.isLoading = true;
            await axios
                .get(
                    window.location.origin + '/product_vue/getLensTypes',
                    {
                        params: {
                            brandId: this.selectedBrand,
                            lensId: this.selectedLens,
                        }
                    }
                )
                .then(response => {
                        this.lensTypes = response.data.lensTypes;
                        this.selectedLensType = null;
                        this.selectedProduct = null;
                        this.products = [];
                        this.clearParams();
                        this.recalculateFinalPrice();
                        this.isLoading = false;
                    }
                );
        },
        async selectedLensType() {
            this.isLoading = true;
            await axios
                .get(
                    window.location.origin + '/product_vue/getProducts',
                    {
                        params: {
                            brandId: this.selectedBrand,
                            lensId: this.selectedLens,
                            lensType: this.selectedLensType,
                        }
                    }
                )
                .then(response => {
                        this.products = response.data.products;
                        this.isLoading = false;
                        this.selectedProduct = null;
                        this.clearParams();
                        this.recalculateFinalPrice();
                        this.isLoading = false;
                    }
                );
        },
        async selectedProduct(selectedProduct) {
            this.isLoading = true;
            this.clearParams();
            await axios
                .get(
                    window.location.origin + '/product_vue/productParams',
                    {
                        params: {
                            selectedProduct: selectedProduct,
                        }
                    }
                )
                .then(response => {
                        this.productParamsRight = response.data.productParams;
                        this.productParamsLeft = response.data.productParams;
                        this.recalculateFinalPrice();
                        this.isLoading = false;
                    }
                );
        },
        productParamRightSphere() {
            this.updateParams('right');
        },
        productParamLeftSphere() {
            this.updateParams('left');
        },
        productParamRightCylinder() {
            this.updateParams('right');
        },
        productParamLeftCylinder() {
            this.updateParams('left');
        },
        modalOpen(value) {
            if (value) {
                document.documentElement.style.overflow = 'hidden';
                document.body.scroll = "no";
            } else {
                document.documentElement.style.overflow = 'auto';
                document.body.scroll = "yes";
            }
        }
    },
    mounted() {
        this.productId = parseInt(document.querySelector('[data-product-id]').dataset.productId);
        this.initData('brand-item');
    }
}
</script>

<style scoped>
.group-wrapper {
    margin: 10px 0;
}

.group-header {
    color: #333;
    font-size: 1.5rem;
    font-weight: 400;
    font-family: "Bebas Neue Cyrillic", sans-serif;
    line-height: 1;
    padding-bottom: 12px;
    border-bottom: 2px solid #007eef;
}

.brand-item {
    min-height: 100px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-radius: 5px;
    background-color: #fff;
    transition: .3s all ease;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.brand-item-selected {
    box-shadow: 0 0 10px rgb(0 126 239 / 20%);
    border: 1px solid #007eef;
    background-color: #007eef;
    color: white;
}

.brand-item-selected .productPrice {
    color: white !important;
}

.brand-item-unselected {
    border: 1px solid #e0e0e0;
}

.productTitle {
    padding: 5px;
    text-align: center;
}

.ProductPriceWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.productPrice {
    white-space: nowrap;
    color: #007eef;
    font-weight: bold;
}

.productPriceInfo {
    font-size: 12px;
    color: #999;
}

.productProperties {
    font-size: 12px;
}

.productPropertyRow {
    border-bottom: 1px dashed #e0e0e0;
}

.productPropertyRow:first-child {
    border-top: 1px dashed #e0e0e0;
}

.additionalParamsWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.additionalParamsWrapper select {
    width: 100%;
    border: 1px solid #e0e0e0;
    padding: 5px;
    margin: 5px 0;
}

.additionalParamsWrapper .paramsWrapperItem {
    flex: 1;
    padding: 5px;
}

.productPropertyRow td {
    padding: 3px 0;
}

.paramsWrapperItemWidth {
    max-width: 400px;
}

.helpBlock {
    display: flex;
}

.helpBlock label, .helpBlock div {
    padding: 5px;
}

.priceDataName {
    padding: 5px;
    text-align: right;
}

.priceDataValue {
    padding: 5px;
    text-align: right;
}

.spacer {
    height: 30px;
}

.productError {
    margin: 20px 0;
}

.popupLinks {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 10px;
}

.popupLinks button {
    padding: 5px;
}

.modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 500;
}

.modal-content {
    background-color: white;
    overflow-y: scroll;
    height: 80%;
    width: 80%;
    padding: 5%;
}
</style>