<template>
  <table border class="tableParams">
    <tr>
      <td rowspan="2" class="bg-head">
        <div>OD</div>
        <div>Праве</div>
      </td>
      <td class="bg-head">
        <div><b class="lat">SPH</b> <span v-if="!receipt" class="required">*</span></div>
      </td>
      <td class="bg-head">
        <div><b class="lat">CYL</b> <span v-if="!receipt" class="required">*</span></div>
      </td>
      <td class="bg-head" v-if="parseFloat(cylinder) !== 0">
        <div><b class="lat">AXIS</b></div>
      </td>
      <td class="bg-head"></td>
    </tr>
    <tr>
      <td>
        <select @change="updateSphere" v-model="sphere">
          <option v-for="productParam in productParams.first">
            {{ productParam }}
          </option>
        </select>
      </td>
      <td>
        <select @change="updateCylinder" v-model="cylinder">
          <option v-for="productParam in productParams.second">
            {{ productParam }}
          </option>
        </select>
      </td>
      <td v-if="parseFloat(cylinder) !== 0">
        <select @change="updateAxis" v-model="axis">
          <option v-for="productParam in productParams.axis">
            {{ productParam }}
          </option>
        </select>
      </td>
      <td>
        <div>
          <button class="btn btn-white btn-clear" @click="clear" type="button">Очистити</button>
        </div>
      </td>
    </tr>
    <tr>
      <td class="bg-head">
        <div>OS</div>
        <div>Ліве</div>
      </td>
      <td>
        <select @change="updateSphere2" v-model="sphere2">
          <option v-for="productParam2 in productParams2.first">
            {{ productParam2 }}
          </option>
        </select>
      </td>
      <td>
        <select @change="updateCylinder2" v-model="cylinder2">
          <option v-for="productParam2 in productParams2.second">
            {{ productParam2 }}
          </option>
        </select>
      </td>
      <td v-if="parseFloat(cylinder) !== 0">
        <select @change="updateAxis2" v-model="axis2">
          <option v-for="productParam2 in productParams2.axis">
            {{ productParam2 }}
          </option>
        </select>
      </td>
      <td>
        <div>
          <button class="btn btn-white btn-clear" @click="clear2" type="button">Очистити</button>
        </div>
      </td>
    </tr>
  </table>
  <!--  <div class="paramsWrapper" id="lense-brand">-->

  <!--    <div class="paramsWrapperItem paramsWrapperEyes">-->
  <!--      <div v-if="eye === 'right'">OD</div>-->
  <!--      <div v-else>OS</div>-->
  <!--    </div>-->
  <!--    <div class="paramsWrapperItem">-->
  <!--      <div><b class="lat">SPH</b> <span v-if="!receipt" class="required">*</span></div>-->
  <!--      <div>-->
  <!--        <select @change="updateSphere" v-model="sphere">-->
  <!--          <option v-for="productParam in productParams.first">-->
  <!--            {{ productParam }}-->
  <!--          </option>-->
  <!--        </select>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="paramsWrapperItem">-->
  <!--      <div><b class="lat">CYL</b> <span v-if="!receipt" class="required">*</span></div>-->
  <!--      <div>-->
  <!--        <select @change="updateCylinder" v-model="cylinder">-->
  <!--          <option v-for="productParam in productParams.second">-->
  <!--            {{ productParam }}-->
  <!--          </option>-->
  <!--        </select>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="paramsWrapperItem">-->
  <!--      <div v-if="parseFloat(cylinder) !== 0"><b class="lat">AXIS</b></div>-->
  <!--      <div v-if="parseFloat(cylinder) !== 0">-->
  <!--        <select @change="updateAxis" v-model="axis">-->
  <!--          <option v-for="productParam in productParams.axis">-->
  <!--            {{ productParam }}-->
  <!--          </option>-->
  <!--        </select>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="paramsWrapperItem">-->
  <!--      <div>&nbsp;</div>-->
  <!--      <div>-->
  <!--        <button class="btn btn-white btn-lg" @click="clear">Очистити</button>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
export default {
  name: "ProductParamsSelector",
  props: {
    eye: {
      type: String,
    },
    productParams: {},
    productParamSphere: {},
    productParamCylinder: {},
    productParamAxis: {},
    productParams2: {},
    productParamSphere2: {},
    productParamCylinder2: {},
    productParamAxis2: {},
    receipt: {},
  },
  emits: [
    'updateSphere',
    'updateCylinder',
    'updateAxis',
    'clearValues',
    'updateSphere2',
    'updateCylinder2',
    'updateAxis2',
    'clearValues2',
  ],
  data() {
    return {
      sphere: null,
      cylinder: null,
      axis: null,
      sphere2: null,
      cylinder2: null,
      axis2: null,
    }
  },
  methods: {
    updateSphere() {
      this.$emit('updateSphere', this.sphere);
      this.productParams.second.forEach(param => {
        if (parseFloat(param) === 0) {
          this.cylinder = param;
          this.updateCylinder();
        }
      });
    },
    updateCylinder() {
      this.$emit('updateCylinder', this.cylinder);
    },
    updateAxis() {
      this.$emit('updateAxis', this.axis);
    },
    clear() {
      this.$emit('clearValues');
    },
    updateSphere2() {
      this.$emit('updateSphere2', this.sphere2);
      this.productParams.second.forEach(param => {
        if (parseFloat(param) === 0) {
          this.cylinder2 = param;
          this.updateCylinder2();
        }
      });
    },
    updateCylinder2() {
      this.$emit('updateCylinder2', this.cylinder2);
    },
    updateAxis2() {
      this.$emit('updateAxis2', this.axis2);
    },
    clear2() {
      this.$emit('clearValues2');
    },
  },
  watch: {
    productParamSphere(input) {
      if (input !== this.sphere) {
        this.sphere = null;
      }
    },
    productParamCylinder(input) {
      if (input !== this.cylinder) {
        this.cylinder = null;
      }
    },
    productParamAxis(input) {
      if (input !== this.axis) {
        this.axis = null;
      }
    },
    productParamSphere2(input) {
      if (input !== this.sphere2) {
        this.sphere2 = null;
      }
    },
    productParamCylinder2(input) {
      if (input !== this.cylinder2) {
        this.cylinder2 = null;
      }
    },
    productParamAxis2(input) {
      if (input !== this.axis2) {
        this.axis2 = null;
      }
    },
  }
}
</script>

<style>
.tableParams {
  border-radius: 5px;
}

@media all and (max-width: 950px) {
  .tableParams {
    font-size: 0.7rem;
  }
}

.tableParams td {
  vertical-align: center;
  padding: 5px;
}
@media all and (max-width: 950px) {
  .tableParams td {
    padding: 2px;
  }
}

.tableParams td div {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tableParams td select {
  width: 80px;
  padding: 5px;
}
@media all and (max-width: 950px) {
  .tableParams td select {
    padding: 0;
  }
}

.bg-head {
  background-color: #edf2fc;
  min-height: 30px;
  min-width: 90px;
  font-weight: bold;
}
@media all and (max-width: 950px) {
  .bg-head {
    min-height: 20px;
    min-width: 30px;
  }
}

@media all and (max-width: 950px) {
  .btn-clear {
    padding:3px;
    font-size: 0.7rem;
  }
}



.paramsWrapper select {
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 5px;
  margin: 5px 0;
}

.paramsWrapperEyes {
  padding: 10px 0;
  border: ;
}

.paramsWrapperEyes div {
  padding: 5px 0;
}

.required {
  color: red;
  font-weight: 700;
}

.lat {
  font-weight: bold;
}

.oko {
  position: relative;
}

.oko-right {
  left: 100px;
  position: absolute;
  font-weight: bold;
}

.oko-left {
  left: 28px;
  position: absolute;
  font-weight: bold;
}
</style>