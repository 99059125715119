<template>
    <div class="product-price__main">
        <span class="product-price__main-value">{{ price }}</span>
        <span class="product-price__main-cur">грн</span>
    </div>
</template>

<script>
export default {
    name: "ProductPrice",
    props: {
        price: {},
    }
}
</script>

<style scoped>

</style>