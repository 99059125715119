import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import {createApp} from 'vue';

import productVue from "./js/ProductVue";
import ProductSelectFull from "./js/ProductSelectFull";

let vueContainer = document.querySelector('#product_vue');
if(parseInt(vueContainer.dataset.type) === 2) {
    const ProductVue = createApp(ProductSelectFull);
    ProductVue.use(VueSweetalert2);
    ProductVue.component('vue-loading', Loading)
    ProductVue.mount('#product_vue');
} else {
    const ProductVue = createApp(productVue);
    ProductVue.component('vue-loading', Loading)
    ProductVue.mount('#product_vue');
}